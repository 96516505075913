import { Customer, Reservation, Venue, VENUES } from './Model'

const CUSTOMER = 'customer'
const RESERVATION = 'reservation'
const VENUE = 'venue'

function setItem(key: string, value: any) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

function getItem(key: string, defaultValue: {}) {
  if (typeof window !== 'undefined') {
    try {
      const value = localStorage.getItem(key)
      if (value) {
        return JSON.parse(value)
      }
    } catch (err) {
      return defaultValue
    }
    return defaultValue
  }
  return defaultValue
}

export function loadCustomer(): Customer {
  return getItem(CUSTOMER, {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  })
}

export function setCustomerStorage(customer: Customer) {
  setItem(CUSTOMER, customer)
}
export function setVenueStorage(venue: Venue) {
  setItem(VENUE, venue)
}

export function getVenueStorage(): Venue {
  return getItem(VENUE, VENUES[0])
}

export function getReservation(): Reservation {
  return getItem(RESERVATION, {})
}

export function setReservation(reservation: Reservation) {
  setItem(RESERVATION, reservation)
}
