import * as React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'

function validatePhone(value) {
  let error
  if (!value) {
    error = 'Required'
  }
  return error
}

export const Input = ({
  label,
  name,
  type = 'text',
  disabled = false,
  placeholder = '',
  autofocus = false,
  errors,
  touched,
}) => {
  return (
    <FormGroup>
      <Field
        type={type}
        name={name}
        className={
          touched[name] && errors[name]
            ? 'is-invalid form-control'
            : 'form-control'
        }
        disabled={disabled}
        autoComplete="off"
        autoFocus={autofocus}
        placeholder={label}
        validate={validatePhone}
      />
    </FormGroup>
  )
}
interface TextAreaProps {
  name: string
  label?: string
  placeholder: string
}
export const TextArea: React.SFC<TextAreaProps> = ({
  name,
  label,
  placeholder,
}) => (
  <FormGroup>
    <Field
      component="textarea"
      name={name}
      className="form-control my-3"
      placeholder={placeholder}
    />
  </FormGroup>
)

export const Select = ({ name, label, items }) => (
  <FormGroup>
    <Field name={name} component="select" className="form-control">
      {items.map(s => (
        <option key={s.key || s} value={s.key || s}>
          {s.value || s}
        </option>
      ))}
    </Field>
  </FormGroup>
)
