import * as React from 'react'

import queryString from 'query-string'

import { formatDateTime } from './util/ReservationTime'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import T from './T'
import ThankYou from './ThankYou'
import ReservationForm from './ReservationForm'
import SelectReservationType from './SelectReservationType'
import ReservationEmailForm from './ReservationEmailForm'
import { Customer, Reservation } from './Model'
import { getVenueStorage, setCustomerStorage } from './storage'
import Colors from './util/Colors'
import { useState, useEffect } from 'react'
import { get, post } from './util/Http'
import LoadingIndicator from './components/LoadingIndicator'
interface Props {
  start_time: string
  end_time: string
  court: number
  price: number
  token?: string
  onToggle: () => void
  onReserve: () => void
}

interface State {
  modal: boolean
  reservation: any
  reserved: boolean
  customer: any
  tokenSend: boolean
  emailReservation: boolean
}
export default function ReservationModal(props: Props) {
  const { court, token } = props
  const [modal, setModal] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [reservation] = useState<any>({
    start_time: props.start_time,
    end_time: props.end_time,
    court: props.court,
    price: props.price,
  })
  const [reserved, setReserved] = useState<boolean>(false)
  const [customer, setCustomer] = useState<Customer>({
    firstname: '',
    lastname: '',
    email: '',
  })

  useEffect(() => {
    if (token) {
      setLoading(true)
      post('/onboarding/verify', { token }).then(res => {
        if (res.email) {
          const newCustomer = { ...customer, email: res.email }
          setCustomer(newCustomer)
          setCustomerStorage(newCustomer)
          setLoading(false)
        }
      })
    }
  }, [])

  const [emailReservation, setEmailReservation] = useState<boolean>(false)

  const toggle = () => {
    setModal(!modal)
    props.onToggle()
  }

  const onCancelEmail = () => {
    setEmailReservation(false)
  }

  const selectCustomer = (customer: Customer) => {
    setCustomerStorage(customer)
    setCustomer(customer)
  }

  const reservationDone = (reservation: Reservation, customer: Customer) => {
    setReserved(true)
    setCustomer(customer)
    setCustomerStorage(customer)
    props.onReserve()
  }

  const loggedIn = customer.email || token
  const showSelectReservationType = !reserved && !loggedIn && !emailReservation
  const showReservationForm = !reserved && loggedIn
  const showEmailForm = emailReservation

  if (loading) {
    return (
      <Modal
        isOpen={true}
        className="ReservationModal"
        modalClassName="ReservationModalModal"
        backdrop="static"
      >
        <ModalBody>
          <div className="my-3">
            <LoadingIndicator containerClass="auto" background="LoadingGrey" />
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="ReservationModal"
      modalClassName="ReservationModalModal"
      backdrop="static"
    >
      <ModalHeader
        toggle={toggle}
        tag="div"
        style={{ borderBottom: 'none', paddingBottom: '0.5rem' }}
        charCode="&times;"
      >
        <div style={{ lineHeight: 1 }}>{formatDateTime(reservation)}</div>
        <div style={{ color: Colors.gray50, fontSize: '0.9rem' }}>
          {getVenueStorage().name} {T('C')}
          {court}
        </div>
        <div style={{ fontSize: '1.5rem' }}>{reservation.price}€</div>
      </ModalHeader>

      {showSelectReservationType && (
        <SelectReservationType
          reservation={reservation}
          onSelectCustomer={selectCustomer}
          onEmailReservation={() => setEmailReservation(true)}
        />
      )}
      {showReservationForm && (
        <ReservationForm
          reservation={reservation}
          customer={customer}
          onToggle={toggle}
          onReservation={reservationDone}
        />
      )}

      {showEmailForm && (
        <ReservationEmailForm
          onToggle={onCancelEmail}
          onClose={() => setModal(false)}
          startTime={props.start_time}
          court={props.court}
        />
      )}

      {reserved && <ThankYou onClose={toggle} email={customer.email} />}
    </Modal>
  )
}
