import queryString from 'query-string'
import * as React from 'react'

import ReservationModal from './ReservationModal'
import T from './T'

import styled from 'styled-components'
import Colors from './util/Colors'
import { useState, useEffect } from 'react'
import { Link } from '@reach/router'

const CourtContainer = styled.div`
  border-right: 1px solid ${Colors.border};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 33%;
  padding: 0.2rem 0.1rem;
  min-width: 30px;

  @media (max-width: 767px) {
    min-width: 50px;
  }
  a {
    display: inline-block;
    text-align: center;
  }
`

const CourtName = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  flex: 1;
`

const CourtPrice = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`

interface CourtProps {
  court: any
  hour: any
}

export default function Court(props: CourtProps) {
  const [reserved, setReserved] = useState<boolean>(false)
  const [reserve, setReserve] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    const { court, hour } = props
    let reserve = false
    const token = parsed.token
    if (parsed.state) {
      const parsedState = queryString.parse(parsed.state)
      reserve =
        court.number === parsedState.court &&
        hour.start_time === parsedState.time
    }
    if (token) {
      const queryCourt = parsed.court
      const queryHour = parsed.hour

      reserve =
        Number(court.number) === Number(queryCourt) &&
        hour.start_time === queryHour

      setToken(token)
    }
    setReserve(reserve)
  }, [])

  const close = () => {
    setReserve(false)
  }

  const { court, hour } = props
  const { reservation, link } = court
  const free = reservation === 'free' && !reserved
  const open = reservation === 'open'
  const classes = free ? 'court--free' : 'court--reserved'
  const saleAllowed = reservation && reservation !== 'ask' && free
  const courtId = `court-${hour.start_time.substring(0, 13)}-${court.number}`

  return (
    <CourtContainer
      id={courtId}
      className={`p1 ${classes} court--${reservation}`}
      onClick={() => {
        if (saleAllowed) {
          setReserve(true)
        }
      }}
    >
      {reserve && (
        <ReservationModal
          start_time={hour.start_time}
          end_time={hour.end_time}
          price={court.price}
          court={court.number}
          onToggle={close}
          onReserve={() => setReserved(true)}
          token={token}
        />
      )}
      <CourtPrice>
        {free && <>{court.price}€</>}
        {open && (
          <a href={link || 'https://www.biitsi.fi/app'}>{court.level}</a>
        )}
        {reservation === 'ask' && <Link to="/contact">{T('Ask')}</Link>}
      </CourtPrice>
    </CourtContainer>
  )
}
