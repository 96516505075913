import * as React from 'react'
import styled from 'styled-components'
import { CalendarContainer } from './Calendar'
import { Venue, VENUES } from './Model'
import Colors from './util/Colors'
import { Link } from 'gatsby'

const VenueItem = styled.div`
  margin-right: 1rem;
  cursor: pointer;
  padding: 5px;
  color: ${Colors.inactiveTint};
  text-transform: uppercase;
  font-size: 1.1rem;
`

interface Props {
  venue: Venue
}

function CalendarHeader(props: Props) {
  return (
    <CalendarContainer>
      <div className="row mt-3">
        <div className="col-lg-6">
          <div className="">
            <div className="d-flex flex-row mb-2">
              {VENUES.map(v => (
                <Link to={'/reserve?venue=' + v.path} key={v.id}>
                  <VenueItem
                    style={
                      v.id === props.venue.id
                        ? {
                            fontWeight: 'bold',
                            backgroundColor: '#000',
                            color: Colors.primary,
                            padding: '5px 10px',
                          }
                        : {}
                    }
                  >
                    {v.name}
                  </VenueItem>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CalendarContainer>
  )
}

export default CalendarHeader
