export interface Customer {
  firstname: string
  lastname: string
  email?: string
  phone?: string
  locale?: string
  fb_user_id?: string
  google_id?: string
  reservationType?: 'email' | 'facebook' | 'google'
  token?: string
}

export interface Reservation {
  start_time: string
  person_count: string
  title: string
  comment: string
  venue_id: number
  court: number
  hour: string
}

export interface Venue {
  id: number
  name: string
  path: string
}

export const VENUES: Venue[] = [
  {
    id: 3,
    name: 'Teras',
    path: 'teras',
  },
  {
    id: 7,
    name: 'TERAS Haven Rannaareen',
    path: 'kakumae',
  },
]
