import * as React from 'react'
import Day, { DayName, DayContainer } from './Day'
import styled from 'styled-components'
import { HourContainer } from './Hour'

const WeekContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
`
const Week = ({ days, hoursTitles }) => (
  <WeekContainer>
    <DayContainer dayTitle={true}>
      <DayName dayTitle={true}>&nbsp;</DayName>
      {hoursTitles.map(h => (
        <HourContainer key={h} hourTitle={true}>
          {h.split('-').map((i: string) => (
            <div key={i}>{i}</div>
          ))}
        </HourContainer>
      ))}
    </DayContainer>
    {days.map(d => (
      <Day key={d.title} day={d.title} hours={d.hours} />
    ))}
  </WeekContainer>
)

export default Week
