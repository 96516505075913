import * as React from 'react'
import styled, { css } from 'styled-components'

import Court from './Court'
import Colors from './util/Colors'

export const HourContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid ${Colors.border};
  flex: 1 1 0;

  ${props =>
    props.hourTitle &&
    css`
      flex-direction: column;
      justify-content: 'space-between';
      border: 1px solid ${Colors.border};
      border-bottom: none;
      font-size: 0.8rem;
      white-space: nowrap;
      align-items: center;
      height: 50px;
      padding: 5px;
      :last-child {
        border-bottom: 1px solid ${Colors.border};
        height: 51px;
      }
    `};
`

const Hour = ({ hour }) => (
  <HourContainer>
    {hour.courts.map(c => (
      <Court key={c.number} court={c} hour={hour} />
    ))}
  </HourContainer>
)

export default Hour
