import React from 'react'
import Layout from '../components/layout'
import Calendar, { CalendarProps } from '../calendar/Calendar'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import ErrorBoundary from '../components/ErrorBoundary'

const CalendarContainer = styled.div`
  padding: 0.5rem 1.5rem;
  background-color: #fff;
`

const calendarProps: CalendarProps = {
  locale: 'ee',
  facebookAppId: '202750875425',
  googleClientId:
    '424496699358-mntasa0p52c8e9nhntqr720m9r91qti4.apps.googleusercontent.com',
  introText: '',
  bookingText: '',
  apiUrl: 'https://api.biitsi.fi',
  //apiUrl: 'http://localhost:3001',
}

const CalendarPage = ({ data }) => {
  const reservation = data.allContentfulReservation.edges[0].node
  const intro = reservation.intro.childMarkdownRemark.rawMarkdownBody
  const booking = reservation.booking.childMarkdownRemark.rawMarkdownBody
  calendarProps.introText = intro
  calendarProps.bookingText = booking
  const locale = reservation.node_locale
  return (
    <ErrorBoundary>
      <Layout
        locale={reservation.node_locale}
        page={{
          slug: 'reserve',
          metaTitle: 'Reserve Teras Beach Tallinna',
        }}
      >
        <CalendarContainer>
          <Calendar
            {...calendarProps}
            locale={locale === 'et' ? 'ee' : locale}
          />
        </CalendarContainer>
      </Layout>
    </ErrorBoundary>
  )
}

export default CalendarPage

export const query = graphql`
  query ReservationQuery($locale: String!) {
    allContentfulReservation(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          node_locale
          id
          intro {
            id
            childMarkdownRemark {
              id
              rawMarkdownBody
            }
          }
          booking {
            id
            childMarkdownRemark {
              id
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`
