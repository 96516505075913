import * as React from 'react'

import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { ModalBody } from 'reactstrap'
import TranslatedContent from './TranslatedContent'
import LoadingIndicator from './components/LoadingIndicator'
import { useState } from 'react'
import T from './T'

import styled from 'styled-components'
import config from './config'
import { Customer, Reservation } from './Model'
import { loadCustomer } from './storage'
import Colors from './util/Colors'

const ReservationTypeButtons = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin: 0 auto;
  button {
    width: 100%;
    margin-bottom: 15px;
  }
`
const BookingText = styled.div`
  ul {
    list-style: none;
    margin: 1rem 0;
  }
  li {
    position: relative;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    &:before {
      content: '✓';
      color: ${Colors.green};
      position: absolute;
      left: -30px;
      top: 10px;
      font-size: 1rem;
    }
  }
`
interface Props {
  onSelectCustomer: (customer: Customer) => void
  reservation: Reservation
  onEmailReservation: () => void
}

interface State {
  mobileBackLoading: boolean
}
export default function SelectReservationType(props: Props) {
  const { reservation } = props
  const [mobileBackLoading, setMobileBackLoading] = useState<boolean>(
    window.location.href.includes('state')
  )
  React.useEffect(() => {
    setTimeout(() => {
      setMobileBackLoading(false)
    }, 2000)
  })

  const responseFacebook = (response: any) => {
    const local = loadCustomer()
    const customer: Customer = {
      email: response.email,
      firstname: response.first_name,
      lastname: response.last_name,
      fb_user_id: response.id,
      reservationType: 'facebook',
      phone: local.phone,
    }

    props.onSelectCustomer(customer)
  }

  const responseGoogle = (response: any) => {
    const local = loadCustomer()
    const obj = response.profileObj
    if (!obj) {
      return
    }
    const customer = {
      email: obj.email,
      firstname: obj.givenName,
      lastname: obj.familyName,
      google_id: obj.googleId,
      reservationType: 'google',
      phone: local.phone,
    }
    props.onSelectCustomer(customer)
  }

  const state = `court=${reservation.court}&time=${reservation.start_time}`
  const redirectUrl = window.location.origin + '/reserve'
  if (mobileBackLoading) {
    return (
      <ModalBody>
        <LoadingIndicator containerClass="auto" background="LoadingGrey" />
      </ModalBody>
    )
  }
  return (
    <ModalBody>
      <BookingText>
        <TranslatedContent value={config.bookingText} />
      </BookingText>
      <ReservationTypeButtons className="mb-4 mt-4">
        <GoogleLogin
          clientId={config.googleClientId}
          buttonText="Google"
          className="btn btn-secondary btn-google btn-login btn-lg justify-content-center"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
        <button
          onClick={props.onEmailReservation}
          className="btn btn-primary btn-login-action btn-lg d-flex justify-content-center align-items-center"
        >
          {T('Email')}
        </button>
      </ReservationTypeButtons>
    </ModalBody>
  )
}
