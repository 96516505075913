import * as React from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import { Formik, Form, FormikActions } from 'formik'
import { Input, Select } from './components/Inputs'
import { post } from './util/Http'
import LoadingIndicator from './components/LoadingIndicator'
import { ModalFooter, ModalBody, Button } from 'reactstrap'
import T from './T'
import { Customer, Reservation } from './Model'
import { getVenueStorage, loadCustomer } from './storage'
import Colors from './util/Colors'
import config from './config'

interface FormItems {
  lastname: string
  firstname: string
  email: string
  phone: string
  person_count: string
  title: string
  comment: string
  nameAndEmail: string
}
interface Props {
  reservation: Reservation
  onReservation: (reservation: any, customer: any) => void
  onToggle: () => void
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ButtonCancel = styled(Button)`
  font-size: 0.9rem;
  color: #5e6977;
`

const SecurityLink = styled.a`
  margin-top: 1rem;
  display: block;
  font-size: 0.8rem;
  color: ${Colors.gray50};
  font-weight: 400;
`

const PEOPLE_COUNT = [
  { key: '< 8', value: `${T('Under')} 8 henkilöä` },
  { key: '9-12', value: '9-12 henkilöä' },
  { key: '> 12', value: `${T('Over')} 12 henkilöä` },
  { key: '>20 ', value: `${T('Over')} 20 henkilöä` },
]

function ReservationForm(props: Props) {
  const { reservation } = props
  const customer = loadCustomer()
  const [reserving, setReserving] = React.useState<boolean>(false)
  const [] = React.useState<string>(customer.email || '')
  const [alreadyReserved, setAlreadyReserved] = React.useState<boolean>(false)

  const parsed = queryString.parse(window.location.search)
  const { token } = parsed

  const submit = (customer: Customer, reservation: Reservation) => {
    setReserving(true)

    const res = {
      ...reservation,
      venue_id: String(getVenueStorage().id),
    }

    post('/customer_reservations?locale=' + config.locale, {
      customer,
      reservation: res,
      token,
      locale: config.locale,
    })
      .then(r => {
        props.onReservation(r, customer)
      })
      .catch(() => {
        setReserving(false)
        setAlreadyReserved(true)
      })
  }

  if (reserving) {
    return (
      <ModalBody>
        <div className="my-3">
          <LoadingIndicator containerClass="auto" background="LoadingGrey" />
        </div>
      </ModalBody>
    )
  }

  let initialValue: FormItems = {
    phone: customer.phone || '',
    title: 'Beach Volley',
    comment: '',
    firstname: customer.firstname,
    lastname: customer.lastname,
    person_count: '< 8',
    email: customer.email || '',
    nameAndEmail: customer.lastname
      ? `${customer.firstname} ${customer.lastname} ${customer.email}`
      : customer.email || '',
  }

  const isEmail = customer.reservationType === 'email' || token

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(
        formItems: FormItems,
        { setSubmitting }: FormikActions<FormItems>
      ) => {
        customer.phone = formItems.phone
        customer.firstname = formItems.firstname
        customer.lastname = formItems.lastname
        reservation.person_count = formItems.person_count
        reservation.title = formItems.title
        reservation.comment = formItems.comment
        submit(customer, reservation)
        setSubmitting(false)
      }}
      render={({ values, errors, touched }) => (
        <Form>
          <ModalBody>
            <>
              <div className="my-3">
                {!isEmail && (
                  <span style={{ padding: '0.5rem', fontWeight: 'bold' }}>
                    {values.firstname} {values.lastname}
                  </span>
                )}
                <span>{values.email}</span>
              </div>
              {isEmail && (
                <>
                  <div className="reservation-row">
                    <Input
                      name="firstname"
                      label={T('FirstName')}
                      errors={errors}
                      touched={touched}
                      disabled={!isEmail}
                    />
                  </div>
                  {customer.lastname.length === 0 && (
                    <div className="reservation-row">
                      <Input
                        name="lastname"
                        label={T('LastName')}
                        errors={errors}
                        touched={touched}
                        disabled={!isEmail}
                      />
                    </div>
                  )}
                </>
              )}
            </>
            <div className="reservation-row">
              <Input
                name="phone"
                label={`${T('Phone')} *`}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="reservation-row">
              <Select
                name="title"
                label={T('Purpose')}
                items={['Beach Volley', 'Beach Tennis', 'Foot Volley']}
              />
            </div>

            <SecurityLink href={T('PrivacyLink')} target="_blank">
              {T('PrivacyPolicy')}
            </SecurityLink>
            {alreadyReserved && (
              <div className="text-danger my-2">{T('Sorry_reserved')}</div>
            )}
          </ModalBody>

          <ModalFooter style={{ borderTopWidth: 0 }}>
            <ButtonContainer>
              <ButtonCancel color="link" onClick={props.onToggle}>
                {T('Cancel')}
              </ButtonCancel>
              <Button
                color="primary"
                size="lg"
                disabled={reserving}
                type="submit"
              >
                {T('Reserve')}
              </Button>
            </ButtonContainer>
          </ModalFooter>
        </Form>
      )}
    />
  )
}

export default ReservationForm
