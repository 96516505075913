import React from 'react'

// Sentry.init({
//  dsn: "https://6da3980e903f4f24b819f6307b666828@sentry.io/1459048"
// });
// should have been called before using it here
// ideally before even rendering your react app

interface State {
  error: any
  eventId: any
}

export default class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props)
    this.state = { error: null, eventId: null }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error })
    /*
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
    */
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <a
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </a>
      )
    } else {
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}
