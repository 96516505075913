import { format, parseISO } from 'date-fns'
import config from '../config'
import { Reservation } from '../Model'

const toLocalDate = d => parseISO(d.slice(0, -1))

const dayMapper = {
  Mon: 'Es',
  Tue: 'Te',
  Wed: 'Ko',
  Thu: 'Ne',
  Fri: 'Re',
  Sat: 'La',
  Sun: 'Pu',
}
const dayMapperFi = {
  Mon: 'Ma',
  Tue: 'Ti',
  Wed: 'Ke',
  Thu: 'To',
  Fri: 'Pe',
  Sat: 'La',
  Sun: 'Su',
}

const isEstonian = () => config.locale && config.locale === 'ee'
const isFinnish = () => config.locale && config.locale === 'fi'

export const formatDateTime = (reservation: Reservation) => {
  return formatDate(reservation) + ' ' + formatHours(reservation)
}

export const formatDate = (reservation: Reservation) => {
  const date = toLocalDate(reservation.start_time)
  const dayNameEn = format(date, 'ccc')
  const dayName = isEstonian()
    ? dayMapper[dayNameEn]
    : isFinnish()
    ? dayMapperFi[dayNameEn]
    : dayNameEn
  return reservation.regular ? dayName : dayName + ' ' + format(date, ' dd.MM.')
}

export const formatHours = reservation => {
  const prefix = isEstonian() ? 'kl ' : ''
  return (
    prefix +
    format(toLocalDate(reservation.start_time), 'HH:mm') +
    '-' +
    format(toLocalDate(reservation.end_time), 'HH:mm')
  )
}
