import 'airbnb-browser-shims'
import * as React from 'react'
import queryString from 'query-string'

import CalendarHeader from './CalendarHeader'
import { get } from './util/Http'
import LoadingIndicator from './components/LoadingIndicator'
import T from './T'
import Week from './Week'
import config from './config'
import styled from 'styled-components'
import { GlobalStyles } from './GlobalStyles'
import { setVenueStorage, getVenueStorage } from './storage'
import { Venue, VENUES } from './Model'

export const CalendarContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const WeekResponsive = styled.div`
  @media (max-width: 767px) {
    display: flex;
    overflow-x: scroll;
    margin-left: 50px;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const CalendarMain = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow-x: visible;
  width: 100%;
  @media (max-width: 900px) {
    width: 100vw;
    margin-left: -1.5rem;
  }
`

export interface CalendarProps {
  locale: string
  facebookAppId: string
  googleClientId: string
  introText: string
  bookingText: string
  apiUrl: string
}

export function initialVenue(): Venue {
  if (typeof window !== 'undefined') {
    const parsed = queryString.parse(window.location.search)
    const { venue } = parsed
    if (venue) {
      const foundVenues = VENUES.filter(v => v.path === venue)
      if (foundVenues && foundVenues.length > 0) {
        const firstVenue = foundVenues[0]
        setVenueStorage(firstVenue)
        return firstVenue
      } else {
        const defaulVenue = VENUES[0]
        setVenueStorage(defaulVenue)
        return defaulVenue
      }
    }
  }
  return getVenueStorage()
}

function Calendar(props: CalendarProps) {
  const initVenue = initialVenue()
  const [weeks, setWeeks] = React.useState<any>([])
  const [hours, setHours] = React.useState<any>([])
  const [nextWeek, setNextWeek] = React.useState<string>('')
  const [venue, setVenue] = React.useState<Venue>(initVenue)
  const [loadingMore, setLoadingMore] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  Object.keys(props).forEach(key => {
    config[key] = props[key]
  })

  function loadData(current: Venue) {
    setLoading(true)
    const url = `/venues/${current.id}/calendar`
    const locale = props.locale
    get(url + `?weeks=2&locale=${locale}`).then(data => {
      setWeeks(data.weeks)
      setHours(data.hours)
      setNextWeek(data.nextWeek)
      setLoading(false)
    })
  }
  if (typeof window !== 'undefined') {
    React.useEffect(() => {
      const currentVenue = initialVenue()
      setVenue(currentVenue)
      loadData(currentVenue)
    }, [window.location.search])
  }

  const loadMoreWeeks = () => {
    setLoadingMore(true)
    const url = `/venues/${venue.id}/calendar/${nextWeek}?locale=${props.locale}`
    get(url).then(data => {
      setWeeks(weeks.concat(data.weeks))
      setNextWeek(data.nextWeek)
      setLoadingMore(false)
    })
  }

  if (weeks.length === 0 || loading) {
    return <LoadingIndicator />
  }
  return (
    <CalendarContainer>
      <GlobalStyles />
      <CalendarHeader venue={venue} />
      <CalendarMain className="mt-2">
        <WeekResponsive>
          {weeks.map((week, index) => (
            <Week key={index} days={week} hoursTitles={week[0].hours_titles} />
          ))}
        </WeekResponsive>
      </CalendarMain>
      <button
        className="btn btn-outline-primary mb-3 hidden-mobile"
        onClick={loadMoreWeeks}
        disabled={loadingMore}
      >
        {!loadingMore && <span>{T('load_more')}</span>}
        {loadingMore && <span>Loading...</span>}
      </button>
    </CalendarContainer>
  )
}

export default Calendar
