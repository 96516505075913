import * as React from 'react'
import Hour from './Hour'
import Colors from './util/Colors'
import styled, { css } from 'styled-components'
import { isAfter, parseISO } from 'date-fns'

export const DayContainer = styled.div`
  flex: 1 1 10%;
  margin-right: 5px;
  box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.2);

  ${props =>
    props.dayTitle &&
    css`
      flex: 1 1 3%;
      @media (max-width: 767px) {
        position: absolute;
        left: 0;
        width: 50px;
      }
    `};

  ${props =>
    props.past &&
    css`
      @media (max-width: 767px) {
        display: none;
      }
    `};
`

export const DayName = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  white-space: nowrap;
  border-top: 1px solid ${Colors.border};
  border-bottom: 1px solid ${Colors.border};
  border-right: 1px solid ${Colors.border};

  background-color: #000;
  color: ${Colors.primary};
  text-transform: uppercase;
  font-weight: 700;
  ${props =>
    props.dayTitle &&
    css`
      border-bottom: none;
      border-left: 1px solid ${Colors.border};
    `};
`

const Day = ({ day, hours }) => (
  <DayContainer
    past={!isAfter(parseISO(hours[hours.length - 1].start_time), new Date())}
  >
    <DayName>{day}</DayName>
    {hours.map(h => (
      <Hour key={h.start_time} hour={h} />
    ))}
  </DayContainer>
)

export default Day
