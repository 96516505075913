import { createGlobalStyle } from 'styled-components'
import Colors from './util/Colors'

export const GlobalStyles = createGlobalStyle`
  body.modal-open {
    overflow: hidden;
    height: 100%;
  }

  @media (max-width: 1024px) {
    .ReservationModalModal {
      background-color: #fff;
    }
  }
  
  @media (max-width: 1024px) {
    .modal-open #calendar-page,
    .modal-open .footer,
    .modal-open nav.navbar {
      display: none;
    }
  }
  

  .court--free {
    cursor: inter;
    border-left: 3px solid #52c41a;    
    cursor:pointer;
  }
  

  .court--free:hover {
    background-color: ${Colors.primary};
  }
  
  .court--past {
    background-color: #f5f5f5;
    cursor: default;
  }

  .court--reserved {
    background-color: #F7F6F5;
    cursor: default;
    border-left: none;
  }

  .court--ask {    
    border-left: 3px solid #1890ff;        
  }

  .court--open {
    cursor: inter;
    border-left: 3px solid #1890ff;    
    cursor:pointer;
  }

   .ReservationModal .modal-body {
    padding-top: 0;
  }

  .modal-header .close {
    font-weight:400;
    color:${Colors.inactiveTint}
  }
  
  @media (max-width: 767px) {
    .ReservationModal.modal-dialog {
      max-width: none;
      margin: 0;
      background-color: #fff;
    }
    .ReservationModal .modal-content {
      border-radius: 0;
      min-height: 100vh;
    }
  }
  .ReservationModal .modal-title {
    text-transform: uppercase;
    position: relative;
    padding-bottom: 0.5rem;
    font-size: 1.1rem;
  }
  .ReservationModal .modal-title:after {
    content: '';
    border-bottom: 4px solid ${Colors.primary};
    max-width: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .ReservationModal .modal-content {
    background-color: #f5f5f5;
  }
  .ReservationModal .modal-content input,
  .ReservationModal .modal-content select,
  .ReservationModal .modal-content textarea {
    border: none;
    padding: 0.8rem 0.5rem;
    font-size: 1rem;
  }
  .ReservationModal .modal-content input,
  .ReservationModal .modal-content select.form-control {
    height: 50px;
  }
  .ReservationModal .modal-content .is-invalid.form-control {
    border: 2px solid #dc3545;
  }
  .ReservationModal .modal-content .reservation-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
  .ReservationModal .modal-content .form-group {
    flex: 1;
    margin-bottom: 0;
    margin-bottom: 10px;
  }
  .ReservationModal .modal-content label {
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    font-size: 0.8rem;    
    color: ${Colors.gray50}
  }

   .btn-login-action {
      text-transform: uppercase;
      font-size: 1rem;
    }
    
    .btn-login {
      background-size: 70px 70px;
      background-repeat: no-repeat;
      background-position: 0 50%;
      text-transform: uppercase;
      font-size: 1rem;
    }

    button.btn-facebook {
      background-color: #4267b2;
      color: #fff;
      background-image: url(https://images.contentful.com/6ir18fbmm97z/1wb1HxwW4ACY4e0wGwcmmm/af4433f752ee99a5b66f165db186668e/facebook-icon-white.svg);
    }

    button.btn-facebook:hover {
      background-color: #4267b2;
      color: #fff;
    }

    button.btn-google {
      background-color: #fff;
      background-position-x: -5px;    
      color: #333;
    }

    .btn-google:hover {
      background-color: #fff;
      color: #333;
    }

    .form-control:disabled, .form-control[readonly] {
      background-color:#f5f5f5;
    }
`
