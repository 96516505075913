import * as React from 'react'
import styled from 'styled-components'
import { Formik, Form, FormikActions } from 'formik'
import { Input } from './components/Inputs'
import { post } from './util/Http'
import LoadingIndicator from './components/LoadingIndicator'
import { ModalFooter, ModalBody, Button } from 'reactstrap'
import T from './T'
import { setCustomerStorage, loadCustomer, getVenueStorage } from './storage'
import { ButtonCancel } from './ReservationForm'
import config from './config'
interface FormItems {
  email: string
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

interface Props {
  onToggle: () => void
  onClose: () => void
  startTime: string
  court: string
}
export default function ReservationEmailForm(props: Props) {
  const [reserving, setReserving] = React.useState<boolean>(false)
  const [sent, setSent] = React.useState<boolean>(false)
  const { court, startTime, onClose, onToggle } = props

  const submit = (email: string) => {
    setReserving(true)
    const customer = loadCustomer()
    customer.email = email
    customer.reservationType = 'email'
    setCustomerStorage(customer)

    const venue = getVenueStorage()
    const returnUrl = `${
      window.location.origin
    }/reserve?court=${court}&hour=${startTime}&venue=${venue.path.toLowerCase()}&locale=${
      config.locale
    }`

    post('/onboarding/create_request', {
      email,
      return_url: returnUrl,
      locale: config.locale,
    })
      .then(() => {
        setReserving(false)
        setSent(true)
      })
      .catch(() => {
        setReserving(false)        
      })
  }

  if (reserving) {
    return (
      <ModalBody>
        <LoadingIndicator containerClass="auto" background="LoadingGrey" />
      </ModalBody>
    )
  }
  return (
    <Formik
      initialValues={{
        email: loadCustomer().email || '',
      }}
      onSubmit={(
        formItems: FormItems,
        { setSubmitting }: FormikActions<FormItems>
      ) => {
        submit(formItems.email)
        setSubmitting(false)
      }}
      render={({ values, errors, touched }) => (
        <Form>
          <ModalBody>
            {sent && (
              <div className="my-2">
                <span>{T('Reservation_Link_Sent')}:</span>
                <strong> {values.email}</strong>
                <p>{T('Reservation_Link_Continue')}</p>
              </div>
            )}
            {!sent && (
              <>
                <div className="my-3">{T('Reservation_Link_Intro')}</div>
                <div className="reservation-row">
                  <Input
                    name="email"
                    label={T('Email')}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </>
            )}
          </ModalBody>

          <ModalFooter style={{ borderTopWidth: 0 }}>
            {sent && (
              <Button color="outline-primary" onClick={onClose}>
                {T('Close')}
              </Button>
            )}
            {!sent && (
              <>
                <ButtonContainer>
                  <ButtonCancel color="link" onClick={onToggle}>
                    {T('Cancel')}
                  </ButtonCancel>
                  <Button
                    color="primary"
                    size="lg"
                    disabled={reserving}
                    type="submit"
                  >
                    {T('Continue')}
                  </Button>
                </ButtonContainer>
              </>
            )}
          </ModalFooter>
        </Form>
      )}
    />
  )
}
