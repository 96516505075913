import * as React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import T from './T'

interface Props {
  onClose: () => void
  email: string
}

function ThankYou(props: Props) {
  const { onClose, email } = props
  return (
    <div>
      <ModalBody>
        <div className="my-3">
          <p style={{ marginBottom: 0 }}>
            {T('Reservation_confirmation_sent_to')} <strong>{email}</strong>
          </p>
          {T('Thank_you')}
        </div>
      </ModalBody>
      <ModalFooter style={{ borderTopWidth: 0 }}>
        <Button color="outline-primary" onClick={onClose} size="lg">
          {T('Close')}
        </Button>
      </ModalFooter>
    </div>
  )
}

export default ThankYou
