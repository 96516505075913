import * as React from 'react'

import styled, { css } from 'styled-components'
import Colors from '../util/Colors'

const LoadingIndicatorContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.auto &&
    css`
      height: auto;
    `};
`

const Loading = styled.div<{ background: string }>`
  font-size: 10px;
  margin: auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: ${Colors.primary};
  background: -moz-linear-gradient(
    left,
    ${Colors.primary} 10%,
    rgba(255, 226, 58, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    ${Colors.primary} 10%,
    rgba(255, 226, 58, 0) 42%
  );
  background: -o-linear-gradient(
    left,
    ${Colors.primary} 10%,
    rgba(255, 226, 58, 0) 42%
  );
  background: -ms-linear-gradient(
    left,
    ${Colors.primary} 10%,
    rgba(255, 226, 58, 0) 42%
  );
  background: linear-gradient(
    to right,
    ${Colors.primary} 10%,
    rgba(255, 226, 58, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  ::before {
    width: 50%;
    height: 50%;
    background: ${Colors.primary};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  ::after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const LoadingIndicator = ({
  containerClass = '',
  background = 'LoadingWhite',
}) => (
  <LoadingIndicatorContainer
    style={{ height: containerClass === 'auto' ? 'auto' : '100vh' }}
  >
    <Loading background={background} className={background}>
      Loading...
    </Loading>
  </LoadingIndicatorContainer>
)

export default LoadingIndicator
