// var ES6Promise = require("es6-promise")
// ES6Promise.polyfill()
import axios from 'axios'
import config from '../config'

function handleStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(new Error(response.statusText))
  }
}

export function get(url) {
  return axios(`${config.apiUrl}${url}`)
    .then(handleStatus)
    .then(res => res.data)
}

export function getFull(url) {
  return axios(`${url}`)
    .then(handleStatus)
    .then(res => res.data)
}

export function post(url, data) {
  return axios
    .post(`${config.apiUrl}${url}`, data)
    .then(handleStatus)
    .then(res => res.data)
}
